// @ts-nocheck
import React from 'react';
import { createRoot } from 'react-dom/client';
import Rails from "@rails/ujs";
import '../assets/stylesheets/antd.less';
import '../assets/stylesheets/layouts/devise.sass';
import Login from '../react/Login';
import TranslationProvider from '../react/utils/TranslationProvider';
import Banner from '../react/Banner';

// This is how react_on_rails can see the HelloWorld in the browser.
if(!window._rails_loaded) Rails.start();

const container = document.getElementById('root');
const root = createRoot(container!);
const props = JSON.parse(container?.dataset.props || '{}')

root.render(
  <React.StrictMode>
    <TranslationProvider {...props}>
      <Login {...props} />
    </TranslationProvider>
  </React.StrictMode>
);