import React from 'react';
import AntForm from "@9troisquarts/ant-form";
import {
  Alert,
  Button,
  Card,
  Col
} from 'antd';
import { useIntl } from 'react-intl';
import last from "lodash/last";
import TranslationProvider from '../utils/TranslationProvider';
import styles from "./index.module.sass";
import { getTenantColorClassName } from '../utils/tenantTheme';
import { post } from '../utils/post';
import { Tenant } from '../types';
import Banner from '../Banner';

interface IProps {
  tenant: Tenant;
  userUnregistered: string;
  locale: string;
  flash: any;
}

const Login: React.FC<IProps> = props => {
  const {
    tenant,
    userUnregistered,
    flash
  } = props;

  const intl = useIntl();

  const onSubmit = (login: any) => {
    const data = { ...login };
    post('/users/sign_in', data);
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", height: "100vh" }}>
      <Banner tenant={tenant} />
      <Card className={styles.wrapper}>
        {tenant.logo && (
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "300px" }} src={tenant.logo} alt={`${tenant.subdomain}_logo`} />
          </div>
        )}
        <div className={`${styles.loginWrapper} ${getTenantColorClassName(tenant.navigationColor, tenant.primaryColor, tenant.secondaryColor)}`}>
          { flash.length > 0 && flash[0][0] === "alert" && (
            <Alert
              message={(
                <div style={{ color: "#DB2828",  fontSize: "16px" }}>
                  {last(flash[0])}
                </div>
              )}
              type="error"
              style={{ width: "400px", marginBottom: "10px" }}
            />
          )}
          { flash.length > 0 && flash[0][0] === "notice" && (
            <Alert
              message={(
                <div style={{ fontSize: "16px" }}>
                  {last(flash[0])}
                </div>
              )}
              type="info"
              style={{ width: "400px", marginBottom: "10px" }}
            />
          )}
          { userUnregistered && (
            <Alert message={intl.formatMessage({ id: "devise.sessions.unregistered" })} />
          )}
          { tenant.authProviders.length === 0 && (
            <>
              <AntForm
                style={{ width: "400px", fontWeight: 600, fontSize: "12px" }}
                layout="vertical"
                schema={[{
                  name: "email",
                  label: intl.formatMessage({ id: "activerecord.attributes.user.email" }),
                  input: {
                    type: "string",
                  }
                }, {
                  name: "password",
                  label: intl.formatMessage({ id: "devise.sessions.password" }),
                  input: {
                    //@ts-ignore
                    type: "password",
                  }
                },
                [
                  {
                    name: "remember_me",
                    style: { fontWeight: 400 },
                    input: {
                      type: "checkbox",
                      text: intl.formatMessage({ id: "devise.sessions.remember_me" })
                    },
                    colProps: { md: 8}
                  },
                  //@ts-ignore
                  (
                    <Col span={8} key="forgottenLink" className={styles.linkWrapper} style={{ fontWeight: 400 }}>
                      <a onClick={() => window.location.href = `/users/password/new`}>{intl.formatMessage({ id: "devise.sessions.forgot_password" })} </a>
                    </Col>
                  )
                ]]}
                object={{}}
                onSubmit={onSubmit}
                actionsWrapperProps={{
                  style: {
                    textAlign: 'center'
                  }
                }}
                submitButtonProps={{
                  size: "large"
                }}
                submitText={intl.formatMessage({ id: 'devise.sessions.log_in' })}
              />
            </>
          )}
          { tenant.authProviders.length > 0 && (
            <>
              { tenant.authProviders.map(provider => (
                <Button onClick={() => window.location.href = `/users/auth/${provider.provider}`}> {intl.formatMessage({ id: 'devise.sessions.log_in' })} </Button>
              ))}
            </>
          )}
        </div>
      </Card>
    </div>
  )
};

export default Login;